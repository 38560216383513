import React, { useEffect, useState, } from "react";
import { Form } from "antd";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getImportanceLocumTenensPageContentRequest,
  postImportanceLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const ImportanceLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { importanceLocumTenensPageData, locumTenensPageStatus, locumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Career Section Cards
  const [careerSectionFirstCardIconUrl, setCareerSectionFirstCardIconUrl] = useState();
  const [careerSectionFirstCardIconFile, setCareerSectionFirstCardIconFile] = useState();
  const [careerSectionSecondCardIconUrl, setCareerSectionSecondCardIconUrl] = useState();
  const [careerSectionSecondCardIconFile, setCareerSectionSecondCardIconFile] = useState();
  const [careerSectionThirdCardIconUrl, setCareerSectionThirdCardIconUrl] = useState();
  const [careerSectionThirdCardIconFile, setCareerSectionThirdCardIconFile] = useState();
  const [careerSectionForthCardIconUrl, setCareerSectionForthCardIconUrl] = useState();
  const [careerSectionForthCardIconFile, setCareerSectionForthCardIconFile] = useState();
  // Who Work Locum Tenens Section Video
  const [ourPeopleSectionThumbnailUrl, setOurPeopleSectionThumbnailUrl] = useState();
  const [ourPeopleSectionThumbnailFile, setOurPeopleSectionThumbnailFile] = useState();
  const [ourPeopleSectionVideoUrl, setOurPeopleSectionVideoUrl] = useState();
  const [ourPeopleSectionVideoFile, setOurPeopleSectionVideoFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getImportanceLocumTenensPageContentRequest(CONTENT_PAGES.IMPORTANCE_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (importanceLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]":importanceLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":importanceLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[careerSection][firstCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.firstCardHeading,
        "detail[careerSection][firstCardFirstBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.firstCardFirstBulletPoint,
        "detail[careerSection][firstCardSecondBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.firstCardSecondBulletPoint,
        "detail[careerSection][secondCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.secondCardHeading,
        "detail[careerSection][secondCardFirstBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.secondCardFirstBulletPoint,
        "detail[careerSection][secondCardSecondBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.secondCardSecondBulletPoint,
        "detail[careerSection][thirdCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.thirdCardHeading,
        "detail[careerSection][thirdCardFirstBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.thirdCardFirstBulletPoint,
        "detail[careerSection][thirdCardSecondBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.thirdCardSecondBulletPoint,
        "detail[careerSection][forthCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.forthCardHeading,
        "detail[careerSection][forthCardFirstBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.forthCardFirstBulletPoint,
        "detail[careerSection][forthCardSecondBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.forthCardSecondBulletPoint,
        "detail[careerSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.heading,
        "detail[careerSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.careerSection?.description,
        "detail[videoSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.heading,
        "detail[videoSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.description,
        "detail[videoSection][firstBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.firstBulletPoint,
        "detail[videoSection][secondBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.secondBulletPoint,
        "detail[videoSection][thirdBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.thirdBulletPoint,
        "detail[videoSection][forthBulletPoint]": importanceLocumTenensPageData?.contentPage?.detail?.videoSection?.forthBulletPoint,
        "detail[ourPeopleSection][providerName]": importanceLocumTenensPageData?.contentPage?.detail?.ourPeopleSection?.providerName,
        "detail[ourPeopleSection][providerJobTitle]": importanceLocumTenensPageData?.contentPage?.detail?.ourPeopleSection?.providerJobTitle,
        "detail[faqsSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.heading,
        "detail[faqsSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.description,
        "detail[faqsSection][firstQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstQuestion,
        "detail[faqsSection][firstAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstAnswer,
        "detail[faqsSection][secondQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondQuestion,
        "detail[faqsSection][secondAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondAnswer,
        "detail[faqsSection][thirdQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdQuestion,
        "detail[faqsSection][thirdAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdAnswer,
        "detail[faqsSection][forthQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthQuestion,
        "detail[faqsSection][forthAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthAnswer,
        "detail[stillQuestionSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.stillQuestionSection?.heading,
        "detail[stillQuestionSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.stillQuestionSection?.description,
        "detail[stillQuestionSection][buttonText]": importanceLocumTenensPageData?.contentPage?.detail?.stillQuestionSection?.buttonText,

        //Seo Values Set
        meta_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: importanceLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: importanceLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? null);
      setOurPeopleSectionThumbnailUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionThumbnail ?? BannerImage);
      setOurPeopleSectionVideoUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionVideo ?? null);
      setCareerSectionFirstCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.careerSectionFirstCardIcon ?? BannerImage);
      setCareerSectionSecondCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.careerSectionSecondCardIcon ?? BannerImage);
      setCareerSectionThirdCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.careerSectionThirdCardIcon ?? BannerImage);
      setCareerSectionForthCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.careerSectionForthCardIcon ?? BannerImage);
      setTwitterImageUrl(importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [importanceLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Importance Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[ourPeopleSectionThumbnail]": ourPeopleSectionThumbnailFile ?? "",
      "contentImages[ourPeopleSectionVideo]": ourPeopleSectionVideoFile ?? "",
      "contentImages[careerSectionFirstCardIcon]": careerSectionFirstCardIconFile ?? "",
      "contentImages[careerSectionSecondCardIcon]": careerSectionSecondCardIconFile ?? "",
      "contentImages[careerSectionThirdCardIcon]": careerSectionThirdCardIconFile ?? "",
      "contentImages[careerSectionForthCardIcon]": careerSectionForthCardIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postImportanceLocumTenensPageContentRequest({ data, id: importanceLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {locumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : locumTenensPageStatus === STATUSES.ERROR ? (
        <div>{locumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Importance Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Career Section */}
                    <BannerImageCard
                      title="Career Section First Card Icon"
                      imageUrl={careerSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setCareerSectionFirstCardIconFile}
                      setImageUrl={setCareerSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section First Card Heading"
                      name="detail[careerSection][firstCardHeading]"
                      placeText="Career Section First Card Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section First Card First Bullet Point"
                      name="detail[careerSection][firstCardFirstBulletPoint]"
                      placeText="Career Section First Card First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section First Card Second Bullet Point"
                      name="detail[careerSection][firstCardSecondBulletPoint]"
                      placeText="Career Section First Card Second Bullet Point"
                    />
                    
                    <BannerImageCard
                      title="Career Section Second Card Icon"
                      imageUrl={careerSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setCareerSectionSecondCardIconFile}
                      setImageUrl={setCareerSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Second Card Heading"
                      name="detail[careerSection][secondCardHeading]"
                      placeText="Career Section Second Card Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Second Card First Bullet Point"
                      name="detail[careerSection][secondCardFirstBulletPoint]"
                      placeText="Career Section Second Card First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Second Card Second Bullet Point"
                      name="detail[careerSection][secondCardSecondBulletPoint]"
                      placeText="Career Section Second Card Second Bullet Point"
                    />
                    
                    <BannerImageCard
                      title="Career Section Third Card Icon"
                      imageUrl={careerSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setCareerSectionThirdCardIconFile}
                      setImageUrl={setCareerSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Third Card Heading"
                      name="detail[careerSection][thirdCardHeading]"
                      placeText="Career Section Third Card Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Third Card First Bullet Point"
                      name="detail[careerSection][thirdCardFirstBulletPoint]"
                      placeText="Career Section Third Card First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Third Card Second Bullet Point"
                      name="detail[careerSection][thirdCardSecondBulletPoint]"
                      placeText="Career Section Third Card Second Bullet Point"
                    />

                    <BannerImageCard
                      title="Career Section Forth Card Icon"
                      imageUrl={careerSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setCareerSectionForthCardIconFile}
                      setImageUrl={setCareerSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Forth Card Heading"
                      name="detail[careerSection][forthCardHeading]"
                      placeText="Career Section Forth Card Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Forth Card First Bullet Point"
                      name="detail[careerSection][forthCardFirstBulletPoint]"
                      placeText="Career Section Forth Card First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Career Section Forth Card Second Bullet Point"
                      name="detail[careerSection][forthCardSecondBulletPoint]"
                      placeText="Career Section Forth Card Second Bullet Point"
                    />
                    {/* Video Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Heading"
                      name="detail[videoSection][heading]"
                      placeText="Video Section Heading"
                    />
                    <TextAreas
                      labelText="Video Section Description"
                      name="detail[videoSection][description]"
                      placeText="Video Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section First Bullet Point"
                      name="detail[videoSection][firstBulletPoint]"
                      placeText="Video Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Second Bullet Point"
                      name="detail[videoSection][secondBulletPoint]"
                      placeText="Video Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Third Bullet Point"
                      name="detail[videoSection][thirdBulletPoint]"
                      placeText="Video Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Forth Bullet Point"
                      name="detail[videoSection][forthBulletPoint]"
                      placeText="Video Section Forth Bullet Point"
                    />
                    <BannerImageCard
                      title="Who Work Locum Tenens Section Thumbnail"
                      imageUrl={ourPeopleSectionThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setOurPeopleSectionThumbnailFile}
                      setImageUrl={setOurPeopleSectionThumbnailUrl}
                      imageText="Change Icon"
                    />
                    <label>Our People Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={ourPeopleSectionVideoUrl}
                      setVideoFile={setOurPeopleSectionVideoFile}
                      setVideoUrl={setOurPeopleSectionVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Our People Section Provider Name"
                      name="detail[ourPeopleSection][providerName]"
                      placeText="Our People Section Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Our People Section Provider Job Title"
                      name="detail[ourPeopleSection][providerJobTitle]"
                      placeText="Our People Section Provider Job Title"
                    />
                    {/* FAQS Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Heading"
                      name="detail[faqsSection][heading]"
                      placeText="FAQS Section Heading"
                    />
                    <TextAreas
                      labelText="FAQS Section Description"
                      name="detail[faqsSection][description]"
                      placeText="FAQS Section Description"
                    />                  
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section First Question"
                      name="detail[faqsSection][firstQuestion]"
                      placeText="FAQS Section First Question"
                    />
                    <TextAreas
                      labelText="FAQS Section First Answer"
                      name="detail[faqsSection][firstAnswer]"
                      placeText="FAQS Section First Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Second Question"
                      name="detail[faqsSection][secondQuestion]"
                      placeText="FAQS Section Second Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Second Answer"
                      name="detail[faqsSection][secondAnswer]"
                      placeText="FAQS Section Second Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Third Question"
                      name="detail[faqsSection][thirdQuestion]"
                      placeText="FAQS Section Third Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Third Answer"
                      name="detail[faqsSection][thirdAnswer]"
                      placeText="FAQS Section Third Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Forth Question"
                      name="detail[faqsSection][forthQuestion]"
                      placeText="FAQS Section Forth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Forth Answer"
                      name="detail[faqsSection][forthAnswer]"
                      placeText="FAQS Section Forth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Still Question Section Heading"
                      name="detail[stillQuestionSection][heading]"
                      placeText="Still Question Section Heading"
                    />
                    <TextAreas
                      labelText="Still Question Section Description"
                      name="detail[stillQuestionSection][description]"
                      placeText="Still Question Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Still Question Section Button Text"
                      name="detail[stillQuestionSection][buttonText]"
                      placeText="Still Question Section Button Text"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default ImportanceLocumTenensPage;
