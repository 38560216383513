import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocumTenensPageContentRequest,
  postLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const LocumTenensPage = () => {

  const dispatch = useDispatch();
  const { locumTenensPageData, locumTenensPageStatus, locumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Benefits Section Cards
  const [benefitsSectionFirstCardIconUrl, setBenefitsSectionFirstCardIconUrl] = useState();
  const [benefitsSectionFirstCardIconFile, setBenefitsSectionFirstCardIconFile] = useState();
  const [benefitsSectionSecondCardIconUrl, setBenefitsSectionSecondCardIconUrl] = useState();
  const [benefitsSectionSecondCardIconFile, setBenefitsSectionSecondCardIconFile] = useState();
  const [benefitsSectionThirdCardIconUrl, setBenefitsSectionThirdCardIconUrl] = useState();
  const [benefitsSectionThirdCardIconFile, setBenefitsSectionThirdCardIconFile] = useState();
  const [benefitsSectionForthCardIconUrl, setBenefitsSectionForthCardIconUrl] = useState();
  const [benefitsSectionForthCardIconFile, setBenefitsSectionForthCardIconFile] = useState();
  // Who Work Locum Tenens Section Video
  const [ourPeopleSectionThumbnailUrl, setOurPeopleSectionThumbnailUrl] = useState();
  const [ourPeopleSectionThumbnailFile, setOurPeopleSectionThumbnailFile] = useState();
  const [ourPeopleSectionVideoUrl, setOurPeopleSectionVideoUrl] = useState();
  const [ourPeopleSectionVideoFile, setOurPeopleSectionVideoFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getLocumTenensPageContentRequest(CONTENT_PAGES.LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (locumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]":locumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":locumTenensPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[bannerSection][firstSubHeading]":locumTenensPageData?.contentPage?.detail?.bannerSection?.firstSubHeading,
        "detail[bannerSection][firstSubDescription]":locumTenensPageData?.contentPage?.detail?.bannerSection?.firstSubDescription,
        "detail[benefitsSection][text]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.text,
        "detail[benefitsSection][heading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.heading,
        "detail[benefitsSection][description]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.description,
        "detail[benefitsSection][firstCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.firstCardHeading,
        "detail[benefitsSection][firstCardDescription]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.firstCardDescription,
        "detail[benefitsSection][secondCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.secondCardHeading,
        "detail[benefitsSection][secondCardDescription]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.secondCardDescription,
        "detail[benefitsSection][thirdCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.thirdCardHeading,
        "detail[benefitsSection][thirdCardDescription]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.thirdCardDescription,
        "detail[benefitsSection][forthCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.forthCardHeading,
        "detail[benefitsSection][forthCardDescription]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.forthCardDescription,
        "detail[videoSection][heading]": locumTenensPageData?.contentPage?.detail?.videoSection?.heading,
        "detail[videoSection][description]": locumTenensPageData?.contentPage?.detail?.videoSection?.description,
        "detail[videoSection][firstBulletPoint]": locumTenensPageData?.contentPage?.detail?.videoSection?.firstBulletPoint,
        "detail[videoSection][secondBulletPoint]": locumTenensPageData?.contentPage?.detail?.videoSection?.secondBulletPoint,
        "detail[videoSection][thirdBulletPoint]": locumTenensPageData?.contentPage?.detail?.videoSection?.thirdBulletPoint,
        "detail[videoSection][forthBulletPoint]": locumTenensPageData?.contentPage?.detail?.videoSection?.forthBulletPoint,
        "detail[ourPeopleSection][providerName]": locumTenensPageData?.contentPage?.detail?.ourPeopleSection?.providerName,
        "detail[ourPeopleSection][providerJobTitle]": locumTenensPageData?.contentPage?.detail?.ourPeopleSection?.providerJobTitle,
        "detail[testimonialsSection][text]": locumTenensPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": locumTenensPageData?.contentPage?.detail?.testimonialsSection?.heading,
        "detail[testimonialsSection][description]": locumTenensPageData?.contentPage?.detail?.testimonialsSection?.description,

        //Seo Values Set
        meta_title: locumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: locumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: locumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: locumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: locumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: locumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: locumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: locumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: locumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: locumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: locumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: locumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: locumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: locumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: locumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: locumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: locumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: locumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: locumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(locumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? null);
      setOurPeopleSectionThumbnailUrl(locumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionThumbnail ?? BannerImage);
      setOurPeopleSectionVideoUrl(locumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionVideo ?? null);
      setBenefitsSectionFirstCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionFirstCardIcon ?? BannerImage);
      setBenefitsSectionSecondCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionSecondCardIcon ?? BannerImage);
      setBenefitsSectionThirdCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionThirdCardIcon ?? BannerImage);
      setBenefitsSectionForthCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionForthCardIcon ?? BannerImage);
      setTwitterImageUrl(locumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(locumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [locumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[ourPeopleSectionThumbnail]": ourPeopleSectionThumbnailFile ?? "",
      "contentImages[ourPeopleSectionVideo]": ourPeopleSectionVideoFile ?? "",
      "contentImages[benefitsSectionFirstCardIcon]": benefitsSectionFirstCardIconFile ?? "",
      "contentImages[benefitsSectionSecondCardIcon]": benefitsSectionSecondCardIconFile ?? "",
      "contentImages[benefitsSectionThirdCardIcon]": benefitsSectionThirdCardIconFile ?? "",
      "contentImages[benefitsSectionForthCardIcon]": benefitsSectionForthCardIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postLocumTenensPageContentRequest({ data, id: locumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {locumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : locumTenensPageStatus === STATUSES.ERROR ? (
        <div>{locumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Sub Heading"
                      name="detail[bannerSection][firstSubHeading]"
                      placeText="Banner Section First Sub Heading"
                    />
                    <TextAreas
                      labelText="Banner Section First Sub Description"
                      name="detail[bannerSection][firstSubDescription]"
                      placeText="Banner Section First Sub Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Benefits Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Text"
                      name="detail[benefitsSection][text]"
                      placeText="Benefits Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Heading"
                      name="detail[benefitsSection][heading]"
                      placeText="Benefits Section Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Description"
                      name="detail[benefitsSection][description]"
                      placeText="Benefits Section Description"
                    />
                    <BannerImageCard
                      title="Benefits Section First Card Icon"
                      imageUrl={benefitsSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionFirstCardIconFile}
                      setImageUrl={setBenefitsSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section First Card Heading"
                      name="detail[benefitsSection][firstCardHeading]"
                      placeText="Benefits Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section First Card Description"
                      name="detail[benefitsSection][firstCardDescription]"
                      placeText="Benefits Section First Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Second Card Icon"
                      imageUrl={benefitsSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionSecondCardIconFile}
                      setImageUrl={setBenefitsSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Second Card Heading"
                      name="detail[benefitsSection][secondCardHeading]"
                      placeText="Benefits Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Second Card Description"
                      name="detail[benefitsSection][secondCardDescription]"
                      placeText="Benefits Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Third Card Icon"
                      imageUrl={benefitsSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionThirdCardIconFile}
                      setImageUrl={setBenefitsSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Third Card Heading"
                      name="detail[benefitsSection][thirdCardHeading]"
                      placeText="Benefits Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Third Card Description"
                      name="detail[benefitsSection][thirdCardDescription]"
                      placeText="Benefits Section Third Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Forth Card Icon"
                      imageUrl={benefitsSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionForthCardIconFile}
                      setImageUrl={setBenefitsSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Forth Card Heading"
                      name="detail[benefitsSection][forthCardHeading]"
                      placeText="Benefits Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Forth Card Description"
                      name="detail[benefitsSection][forthCardDescription]"
                      placeText="Benefits Section Forth Card Description"
                    />
                    {/* Video Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Heading"
                      name="detail[videoSection][heading]"
                      placeText="Video Section Heading"
                    />
                    <TextAreas
                      labelText="Video Section Description"
                      name="detail[videoSection][description]"
                      placeText="Video Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section First Bullet Point"
                      name="detail[videoSection][firstBulletPoint]"
                      placeText="Video Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Second Bullet Point"
                      name="detail[videoSection][secondBulletPoint]"
                      placeText="Video Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Third Bullet Point"
                      name="detail[videoSection][thirdBulletPoint]"
                      placeText="Video Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Forth Bullet Point"
                      name="detail[videoSection][forthBulletPoint]"
                      placeText="Video Section Forth Bullet Point"
                    />
                    <BannerImageCard
                      title="Who Work Locum Tenens Section Thumbnail"
                      imageUrl={ourPeopleSectionThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setOurPeopleSectionThumbnailFile}
                      setImageUrl={setOurPeopleSectionThumbnailUrl}
                      imageText="Change Icon"
                    />
                    <label>Our People Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={ourPeopleSectionVideoUrl}
                      setVideoFile={setOurPeopleSectionVideoFile}
                      setVideoUrl={setOurPeopleSectionVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Our People Section Provider Name"
                      name="detail[ourPeopleSection][providerName]"
                      placeText="Our People Section Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Our People Section Provider Job Title"
                      name="detail[ourPeopleSection][providerJobTitle]"
                      placeText="Our People Section Provider Job Title"
                    />
                    {/* Testimonials Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    <TextAreas
                      labelText="Testimonials Section Description"
                      name="detail[testimonialsSection][description]"
                      placeText="Testimonials Section Description"
                    />                
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default LocumTenensPage;
