import React from "react";
import { Input, Form } from "antd";
import styled from "styled-components";

const BaseInputWrapped = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding-bottom: 30px;
  }
  @media (max-width: 992px) .labelText .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .style_input {
    color: #000000;
    .ant-input {
      height: 50px;
      color: #000000;
      background-color: #f1f1f1 !important;
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;

const BaseInput = ({
  placeText,
  inputIcon,
  labelText,
  name,
  sizes,
  req,
  isUrl,
  isEmail,
  isPhone,
  isInteger,
  isAddress,
  onChange,
  addon,
  marginBottom,
  disabled,
  pattern,
  patternMessage,
  type,
  typeMessage,
  maxLength,
  inputType,
  isFocusElem,
  value,
}) => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})([\s.-]?)\d{3}([\s.-]?)\d{4}$/;
  const addressRegex = /^\d+\s[A-Za-z\s]+,\s[A-Za-z\s]+,\sCA\s\d{5}$/;

  const validateUrl = (rule, value, callback) => {
    if (!isUrl || !value || urlRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid URL");
    }
  };
  // Email Address Validators
  const validateEmail = (rule, value, callback) => {
    if (!isEmail || !value || emailRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid email address");
    }
  };
  // Phone number Validators
  const validatePhone = (rule, value, callback) => {
    if (!isPhone || !value || phoneRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid phone number");
    }
  };
  // Address Validation
  const validateAddress = (rule, value, callback) => {
    if (!value || addressRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid California address");
    }
  };

  const validateInteger = (rule, value, callback) => {
    if (!isInteger || !value || /^[0-9]+$/.test(value)) {
      callback();
    } else {
      callback("Please enter a valid integer");
    }
  };
  return (
    <BaseInputWrapped>
      <Form.Item
        name={name || "name"}
        label={
          labelText != null ? (req != false ? labelText + " *" : labelText) : ""
        }
        className={marginBottom ? `${marginBottom} w-100` : "w-100 labelText"}
        initialValue={value || ""}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
          ...(pattern
            ? [
                {
                  pattern: pattern,
                  message: patternMessage || "Pattern does not match",
                },
              ]
            : []),
          ...(type
            ? [
                {
                  type: type,
                  message: typeMessage || "Type does not match",
                },
              ]
            : []),
          ...(isUrl
            ? [
                {
                  validator: validateUrl,
                },
              ]
            : []),
          ...(isEmail
            ? [
                {
                  validator: validateEmail,
                },
              ]
            : []),
          ...(isPhone
            ? [
                {
                  validator: validatePhone,
                },
              ]
            : []),
          ...(isAddress
            ? [
                {
                  validator: validateAddress,
                },
              ]
            : []),
          ...(isInteger
            ? [
                {
                  validator: validateInteger,
                },
              ]
            : []),
        ]}
      >
        <Input
          ref={isFocusElem}
          addonAfter={addon || ""}
          prefix={inputIcon || ""}
          placeholder={placeText || "Type Here"}
          size={sizes || "large"}
          onChange={onChange}
          disabled={disabled || false}
          autoComplete="off"
          maxLength={maxLength || 500}
          type={inputType || "text"}
          className="style_input"
        />
      </Form.Item>
    </BaseInputWrapped>
  );
};

export default BaseInput;
