import React from 'react';
import { Input, Form } from 'antd';
import styled from "styled-components";
const PasswordInputWrapper = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding-bottom: 30px;
  }
  @media (max-width: 992px) .labelText .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .style_input {
     color: #000000;
    .ant-input {
      height: 50px;
      color: #000000;
      background-color:#F1F1F1 !important;
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;
const PasswordInput = ({
  placeText,
  inputIcon,
  labelText,
  name,
  placetype,
  passwordPolicyData,
  maxLength,
  marginBottom,
}) => {
  const handlePasswordPaste = e => {
    e.preventDefault();
    return false;
  };
  return (
    <PasswordInputWrapper>
      <Form.Item
        name={name || 'name'}
        label={labelText || ''}
        className={marginBottom ? `${marginBottom} w-100` : 'w-100 mb-4'}
        rules={[
          {
            required: true,
            message: 'This field is required!!',
          },
          {
            pattern: passwordPolicyData?.regex || '',
            message: passwordPolicyData?.regex_message || '',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              } else {
                return Promise.reject('Password does not match');
              }
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          prefix={inputIcon || ''}
          type={placetype || ''}
          placeholder={placeText || 'Type Here'}
          maxLength={maxLength || 20}
          // onPaste={handlePasswordPaste}
          className='style_input'
        />
      </Form.Item>
    </PasswordInputWrapper>
  );
};

export default PasswordInput;
