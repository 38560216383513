import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getWhyWorkLocumTenensPageContentRequest,
  postWhyWorkLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const WhyWorkLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { whyWorkLocumTenensPageData, whyWorkLocumTenensPageStatus, whyWorkLocumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Locum Tenens Section
  const [locumTenensSectionImageUrl, setLocumTenensSectionImageUrl] = useState();
  const [locumTenensSectionImageFile, setLocumTenensSectionImageFile] = useState();
  // Reason Section Cards
  const [reasonsSectionFirstCardIconUrl, setReasonsSectionFirstCardIconUrl] = useState();
  const [reasonsSectionFirstCardIconFile, setReasonsSectionFirstCardIconFile] = useState();
  const [reasonsSectionSecondCardIconUrl, setReasonsSectionSecondCardIconUrl] = useState();
  const [reasonsSectionSecondCardIconFile, setReasonsSectionSecondCardIconFile] = useState();
  const [reasonsSectionThirdCardIconUrl, setReasonsSectionThirdCardIconUrl] = useState();
  const [reasonsSectionThirdCardIconFile, setReasonsSectionThirdCardIconFile] = useState();
  // Who Work Locum Tenens Section Video
  const [whoWorkLocumTenensSectionThumbnailUrl, setWhoWorkLocumTenensSectionThumbnailUrl] = useState();
  const [whoWorkLocumTenensSectionThumbnailFile, setWhoWorkLocumTenensSectionThumbnailFile] = useState();
  const [whoWorkLocumTenensSectionVideoUrl, setWhoWorkLocumTenensSectionVideoUrl] = useState();
  const [whoWorkLocumTenensSectionVideoFile, setWhoWorkLocumTenensSectionVideoFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getWhyWorkLocumTenensPageContentRequest(CONTENT_PAGES.WHY_WORK_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (whyWorkLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][subHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.subHeading,
        "detail[bannerSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[locumTenensSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.locumTenensSection?.heading,
        "detail[locumTenensSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.locumTenensSection?.description,
        "detail[reasonsSection][text]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.text,
        "detail[reasonsSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.heading,
        "detail[reasonsSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.description,
        "detail[reasonsSection][firstCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.firstCardHeading,
        "detail[reasonsSection][firstCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.firstCardDescription,
        "detail[reasonsSection][secondCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.secondCardHeading,
        "detail[reasonsSection][secondCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.secondCardDescription,
        "detail[reasonsSection][thirdCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.thirdCardHeading,
        "detail[reasonsSection][thirdCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.reasonsSection?.thirdCardDescription,
        "detail[whoWorkLocumTenensSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.whoWorkLocumTenensSection?.heading,
        "detail[whoWorkLocumTenensSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.whoWorkLocumTenensSection?.description,
        "detail[whoWorkLocumTenensSection][providerName]": whyWorkLocumTenensPageData?.contentPage?.detail?.whoWorkLocumTenensSection?.providerName,
        "detail[whoWorkLocumTenensSection][providerJobTitle]": whyWorkLocumTenensPageData?.contentPage?.detail?.whoWorkLocumTenensSection?.providerJobTitle,
        //Seo Values Set
        meta_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setLocumTenensSectionImageUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.locumTenensSectionImage ?? BannerImage);
      setReasonsSectionFirstCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionFirstCardIcon ?? BannerImage);
      setReasonsSectionSecondCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionSecondCardIcon ?? BannerImage);
      setReasonsSectionThirdCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionThirdCardIcon ?? BannerImage);
      setWhoWorkLocumTenensSectionThumbnailUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.whoWorkLocumTenensSectionThumbnail ?? BannerImage);
      setWhoWorkLocumTenensSectionVideoUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.whoWorkLocumTenensSectionVideo ?? BannerImage);
      setTwitterImageUrl(whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [whyWorkLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Why Work Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[locumTenensSectionImage]": locumTenensSectionImageFile ?? "",
      "contentImages[reasonsSectionFirstCardIcon]": reasonsSectionFirstCardIconFile ?? "",
      "contentImages[reasonsSectionSecondCardIcon]": reasonsSectionSecondCardIconFile ?? "",
      "contentImages[reasonsSectionThirdCardIcon]": reasonsSectionThirdCardIconFile ?? "",
      "contentImages[whoWorkLocumTenensSectionThumbnail]": whoWorkLocumTenensSectionThumbnailFile ?? "",
      "contentImages[whoWorkLocumTenensSectionVideo]": whoWorkLocumTenensSectionVideoFile ?? "",
      _method: 'PUT'
    }
    dispatch(postWhyWorkLocumTenensPageContentRequest({ data, id: whyWorkLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {whyWorkLocumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : whyWorkLocumTenensPageStatus === STATUSES.ERROR ? (
        <div>{whyWorkLocumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Why Work Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Sub Heading"
                      name="detail[bannerSection][subHeading]"
                      placeText="Banner Section Sub Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Locum Tenens Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Locum Tenens Section Heading"
                      name="detail[locumTenensSection][heading]"
                      placeText="Locum Tenens Section Heading"
                    />
                    <TextAreas
                      labelText="Locum Tenens Section Description"
                      name="detail[locumTenensSection][description]"
                      placeText="Locum Tenens Section Description"
                    />
                    <BannerImageCard
                      title="Locum Tenens Section Image"
                      imageUrl={locumTenensSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setLocumTenensSectionImageFile}
                      setImageUrl={setLocumTenensSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Reasons Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Text"
                      name="detail[reasonsSection][text]"
                      placeText="Reasons Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Heading"
                      name="detail[reasonsSection][heading]"
                      placeText="Reasons Section Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Description"
                      name="detail[reasonsSection][description]"
                      placeText="Reasons Section Description"
                    />
                    <BannerImageCard
                      title="Reasons Section First Card Icon"
                      imageUrl={reasonsSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionFirstCardIconFile}
                      setImageUrl={setReasonsSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section First Card Heading"
                      name="detail[reasonsSection][firstCardHeading]"
                      placeText="Reasons Section First Card Heading"
                    />
                    <TextAreas
                     labelText="Reasons Section First Card Description"
                     name="detail[reasonsSection][firstCardDescription]"
                     placeText="Reasons Section First Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Second Card Icon"
                      imageUrl={reasonsSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionSecondCardIconFile}
                      setImageUrl={setReasonsSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Second Card Heading"
                      name="detail[reasonsSection][secondCardHeading]"
                      placeText="Reasons Section Second Card Heading"
                    />
                    <TextAreas
                     labelText="Reasons Section Second Card Description"
                     name="detail[reasonsSection][secondCardDescription]"
                     placeText="Reasons Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Third Card Icon"
                      imageUrl={reasonsSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionThirdCardIconFile}
                      setImageUrl={setReasonsSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Third Card Heading"
                      name="detail[reasonsSection][thirdCardHeading]"
                      placeText="Reasons Section Third Card Heading"
                    />
                    <TextAreas
                     labelText="Reasons Section Third Card Description"
                     name="detail[reasonsSection][thirdCardDescription]"
                     placeText="Reasons Section Third Card Description"
                    />
                    {/* Who Work Locum Tenens Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Who Work Locum Tenens Section Heading"
                      name="detail[whoWorkLocumTenensSection][heading]"
                      placeText="Who Work Locum Tenens Section Heading"
                    />
                    <TextAreas
                      labelText="Who Work Locum Tenens Section Description"
                      name="detail[whoWorkLocumTenensSection][description]"
                      placeText="Who Work Locum Tenens Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Who Work Locum Tenens Section Provider Name"
                      name="detail[whoWorkLocumTenensSection][providerName]"
                      placeText="Who Work Locum Tenens Section Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Who Work Locum Tenens Section Provider Job Title"
                      name="detail[whoWorkLocumTenensSection][providerJobTitle]"
                      placeText="Who Work Locum Tenens Section Provider Job Title"
                    />
                    <BannerImageCard
                      title="Who Work Locum Tenens Section Thumbnail"
                      imageUrl={whoWorkLocumTenensSectionThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setWhoWorkLocumTenensSectionThumbnailFile}
                      setImageUrl={setWhoWorkLocumTenensSectionThumbnailUrl}
                      imageText="Change Icon"
                    />
                    <label>Who Work Locum Tenens Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={whoWorkLocumTenensSectionVideoUrl}
                      setVideoFile={setWhoWorkLocumTenensSectionVideoFile}
                      setVideoUrl={setWhoWorkLocumTenensSectionVideoUrl}
                    />
                    <br/>
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default WhyWorkLocumTenensPage;
