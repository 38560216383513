import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import {
  editConfigurationalLocationRequest,
  getConfigurationalLocationListRequest,
} from "../../../redux/systemConfigurationSlice";
import { BannerImage, STATUSES } from "../../../config/constants/defaultValues";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/shared/GeneralComponents";
import Selects from "../../../components/select/Selects";
import {
  getHospitalTypeListRequest,
  getStateListRequest,
} from "../../../redux/appDataSlice";
import { errorNotification } from "../../../components/notifications/alertNotification";
import BannerImageCard from "../../../components/shared/bannerImageCard";

const LocationEditModal = ({ isModalOpen, setIsModalOpen, modalData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();

  const [thumbnailImageUrl, setThumbnailImageUrl] = useState();
  const [thumbnailImageFile, setThumbnailImageFile] = useState();

  const { StateData, StateStatus, HospitalTypeData, HospitalTypeStatus } =
    useSelector((state) => state.appData);

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        name: modalData?.name,
        postal_code: modalData?.postal_code,
        state_id: modalData?.state.name,
        hospital_type_id: modalData?.hospital_type?.name,
      });
      setBannerSectionImageUrl(modalData?.banner_image ?? BannerImage);
      setThumbnailImageUrl(modalData?.thumbnail_image ?? BannerImage);
    }
  }, [modalData]);

  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };

  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First");
    }
  };

  const onDataChange = ({ state, hospitalType }) => {
    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    // Update only the  property in the new object

    if (state !== undefined) {
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      form.resetFields(["hospital_type_id"]);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
    }
    setSelectedValues(newSelectedValues);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Save Button
  const onSave = async (formData) => {
    var values = {
      ...formData,
      id: modalData?.id,
      state_id: modalData?.state.name === formData.state_id ? modalData?.state.id : formData?.state_id,
      hospital_type_id: modalData?.hospital_type.name === formData.hospital_type_id ? modalData?.hospital_type.id : formData?.hospital_type_id,
      banner_image: bannerSectionImageFile ?? "",
      thumbnail_image: thumbnailImageFile ?? "",
      location_id: modalData?.id,
      status: "1",
      _method: "PUT",
    };
    setIsLoading(true);
    await dispatch(
      editConfigurationalLocationRequest({
        values,
      })
    ).then((data) => {
      if (data.payload.status === 200) {
        dispatch(getConfigurationalLocationListRequest({}));
        setIsModalOpen(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Edit Location</h5>
          </div>
        </div>
        <div className="modal-content">
          <Form
            layout="vertical"
            className="pt-3"
            onFinish={onSave}
            form={form}
          >
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between align-items-start">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <Selects
                    name="state_id"
                    labelText="State"
                    placeText="State"
                    notFoundContent={
                      StateStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        state: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ state: null });
                    }}
                    onClick={getStateData}
                    options={StateData?.collection_list ?? []}
                  />
                  <Selects
                    name="hospital_type_id"
                    labelText="Hospital Type"
                    placeText="Hospital Type"
                    disabled={selectedValues?.state != null ? false : true}
                    value={
                      selectedValues?.hospitalType == null
                        ? undefined
                        : selectedValues.hospitalType
                    }
                    notFoundContent={
                      HospitalTypeStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        hospitalType: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ hospitalType: null });
                    }}
                    onClick={getHospitalTypeData}
                    options={HospitalTypeData ?? []}
                  />
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Location"
                    name="name"
                    req
                    placeText="Add Location"
                  />
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Postal Code"
                    name="postal_code"
                    req
                    isInteger
                    placeText="Add Postal Code"
                  />
                  <BannerImageCard
                    title="Banner Section Image"
                    imageUrl={bannerSectionImageUrl}
                    isBlogImage={true}
                    setImageFile={setBannerSectionImageFile}
                    setImageUrl={setBannerSectionImageUrl}
                    imageText="Change Image"
                  />
                  <BannerImageCard
                    title="Thumbnail Image"
                    imageUrl={thumbnailImageUrl}
                    isBlogImage={true}
                    setImageFile={setThumbnailImageFile}
                    setImageUrl={setThumbnailImageUrl}
                    imageText="Change Image"
                  />
                </div>
                <SaveButton
                  loading={isLoading ? STATUSES.LOADING : false}
                  title="Save" />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default LocationEditModal;
